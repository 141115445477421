import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';

import api from "../lib/request.js";

function EditableImage ({ uploadURL, name, value, index, onValueChange, path, contentStyle, multiple, editable}) {
    const [image, setImage] = useState(value || {});
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleChangeFile (e) {
      setLoading(true);
      const formData = new FormData();

      if (e && e.target && e.target.files && e.target.files.length) {
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append("files", e.target.files[i], e.target.files[i].name);
        }
        formData.append('nows', true);

        const requestData = {
          method: "post",
          url: uploadURL,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        };

        api(requestData).then(res => {
          setLoading(false);
          if (res.ok) {
            // ok!
            e.target.value = '';
            if (res.images && res.images[0]) {
              setImage({url: res.images[0]})
              if (onValueChange) onValueChange(path, {url: res.images[0]});
              toast.success('Image changed!');
            }
          } else if (res.error) {
            alert(res.message);
          }
        }).catch (err => {
          e.target.value = '';
          setLoading(false);
          console.log(err);
        });
      } else {
        setLoading(false);
      }
    }

    function showFileDialog(e, stepPosition) {
      const fileElem = document.getElementById(`fileInput-${name}-${index}`);
      fileElem.click();
    }

    return (
      <>
      {editable && <input type="file" id={`fileInput-${name}-${index}`} onChange={(e) => handleChangeFile(e)} accept="image/*;capture=camera"  {...(multiple ? { multiple: true } : {})} style={{display: 'none'}}/>}
        <div style={{paddingTop: 0, height: 400, textAlign:'center', position:"relative"}}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
        >
            {(hovered && editable) && (
                <div className="btn-group" style={{position: 'absolute', top: 0, right: 0, margin: 5}}>
                  <button className="btn btn-sm btn-outline-dark" onClick={(e) => showFileDialog(e)}>Change</button>
                </div>)}

            { loading ? <Spinner animation="border" /> : <Card.Img variant="top" style={{objectFit: 'cover', maxHeight: 390, width: 'auto', maxWidth: 'inherit', ...contentStyle}} src={image?.url || 'https://placehold.co/400x400'} key={index}/>}
        </div>
      </>
    );
}

export default EditableImage;