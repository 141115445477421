const config = {
  siteName: process.env.REACT_APP_SITE_NAME,
  env: process.env.REACT_APP_ENV,
  prefixPath: process.env.REACT_APP_PREFIX_PATH,
  siteURL: process.env.REACT_APP_SITE_URL,
  wsURL: process.env.REACT_APP_WS_URL,
  publicKey: process.env.REACT_APP_PUBLIC_KEY
};

export default config;
