import React, { useState, useEffect } from 'react';

function EditableTags ({ name, value, index, onValueChange, path, contentStyle, editStyle, editable, linkPrefix}) {
    
    const [edit, setEdit] = useState(false);
    const [tags, setTags] = useState(value || []);

    useEffect(() => {
      if (edit) {
        document.getElementById(`${name}-${index}`).focus();
      }
    }, [edit, name, index]);

    const handleContentClick = (index, content, event) => {
      if (!editable) return;
      event.preventDefault();
      setEdit(true);
      setTags(content);
    };

    function handleChange(event) {
      setTags(getArrayFromText(event.target.value, true));
    }

    function getArrayFromText(txt, onlySplit) {
      let parts = txt.split(',');
      if (onlySplit) return parts;
      parts = parts.map(p => p.trim()).filter(p => p.trim());
      return parts;
    }

    const handleEditableBlur = (event, index) => {
      setEdit(false);
      setTags(getArrayFromText(event.target.value));
      if (onValueChange) onValueChange(path, getArrayFromText(event.target.value));
    };

    const handleKeyPress = (event, index) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleEditableBlur(event, index);
      }
    }

    function getTextValue () {
      return tags.join(',');
    }

    function drawTags() {
      return (
        <>
          {tags.map((tag, itag) => (
            <a style={{marginRight: 5, cursor: editable ? "context-menu" : "pointer"}} key={itag} className="badge text-bg-secondary text-decoration-none" href={`${linkPrefix}/?filterByTag=${tag}`}>{tag}</a>
          ))}
        </>
      );
    }

    return (
      ((edit && editable) ? (
        <input 
          id={`${name}-${index}`}
          key={`${name}-${index}`}
          type="text" 
          value={getTextValue()} 
          onChange={handleChange} 
          onBlur={(e) => handleEditableBlur(e, index)}
          onKeyPress={(e) => handleKeyPress(e, index)}
          style={{...editStyle}}
        />
      ) : (
        <div style={{display: 'inline', cursor: editable ? "context-menu" : ""}} id={`editable-${name}-${index}`} onClick={(e) => handleContentClick(index, tags, e)}>{tags.length ? drawTags() : 'No tags'}</div>
      ))
    );
}

export default EditableTags;