import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { AuthProvider } from './contexts/AuthContext';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import config from './config';
import ProjectsList from './pages/ProjectsList';
import ProjectHome from './pages/Project';
import GuideHome from './pages/Guide';
import GuideHomeUpload from './pages/GuideUpload';
import Page404 from './pages/404';

function App() {
  return (
    <Container>
    <AuthProvider>
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to={`${config.prefixPath}/projects/`} />} />
        <Route path={`${config.prefixPath}/login`} element={<LoginPage />} />
        <Route path={config.prefixPath} element={<HomePage />} />
        <Route path={`${config.prefixPath}/projects`} element={<ProjectsList />} />
        <Route path={`${config.prefixPath}/projects/:project`} element={<ProjectHome />} />
        <Route path={`${config.prefixPath}/projects/:project/guides/:guide`} element={<GuideHome />} />
        <Route path={`${config.prefixPath}/projects/:project/guides/:guide/#step`} element={<GuideHome />} />
        <Route path={`${config.prefixPath}/projects/:project/guides/:guide/remote-upload`} element={<GuideHomeUpload />} />
        <Route path="*"element={<Page404/>}/>
      </Routes>
    </Router>
    </AuthProvider>
    </Container>
  );
}

export default App;
