import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import config from "../config";
import api from "../lib/request.js";
import HeaderNavbar from '../components/HeaderNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRightArrowLeft} from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom';
import Page404 from './404';
import { useAuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import moment from 'moment';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import arrayMove from "array-move";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function ProjectHome() {
  const { isAuthenticated, userRoles } = useAuthContext();

  let { project } = useParams();
  const query = useQuery();
  const filterByTag = query.get('filterByTag');

  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState({title: '', description: ''});

  async function fetchData() {
    try {
      let response = await api.get(`/api/v1/projects/${project}`);
      extractTags(response)
      if (filterByTag) {
        response = filterGuides(response, filterByTag);
      }
      setData(response);
    } catch (e) {
      if (e.statusCode === 404) {
        return setError('404')
      }
      console.log(e);
    }
  }

  function extractTags (data) {
    let parsedTags = [];
    if (data && data.guides && Array.isArray(data.guides)) {
      data.guides.map(g => {
        parsedTags = [...new Set([...parsedTags, ...(g.tags || [])])]
      });
    }
    setTags(parsedTags);
  }

  function filterGuides (data, tag) {
    if (data && data.guides && Array.isArray(data.guides)) {
      data.guides = data.guides.filter(g => (g.tags || []).includes(tag));
    }    
    return data;
  }

  function handleShowModal(type, data) {
    setModalType(type);
    setModalData({
      title: '',
      description: '',
    });
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleInputChange(event) {
    setModalData({
      ...modalData,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit(event) {
    // Prevent form being submitted through HTML
    event.preventDefault();
    setLoading(true);

    if (!modalData?.title.trim()) {
      alert('Title is required')
      setLoading(false);
      return;
    }

    //console.log(modalData);
    try {
      const response = await api.post(`/api/v1/projects/${data.slugURL}/guides`, modalData);
      setLoading(false);
      //console.log('=======response=====', response);
      // Update the state and close the modal
      if (response.ok === true) {
        // Fetch data again
        // fetchData();
        handleCloseModal();
        document.location = `${config.prefixPath}/projects/${data.slugURL}/guides/${response.createdSlugURL}`
      } else {
        if (response.error) {
          alert(response.message);
        }
        //console.error(response);
      }
    } catch (error) {
      setLoading(false);
      //console.error('error', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [project]); // refetch when the `project` prop changes

  if (error === '404') return (<Page404/>);
  if (!data) return;

  function drawTags () {
     return (
        <>
        {tags.length ? <span style={{marginRight: 10}}>Filter by Tag:</span> : ''}
          {tags.map((tag, itag) => (
            <a style={{marginRight: 5, cursor: "pointer"}} key={itag} className={`badge text-bg-${tag===filterByTag ? 'dark' : 'secondary'} text-decoration-none`} href={`${config.prefixPath}/projects/${data.slugURL}/?filterByTag=${tag === filterByTag ? '': tag}`}>{tag}</a>
          ))}
        </>
      );
  }

  function shortText(txt, limit) {
    if ((txt || '').length > limit) {
      txt = txt.substring(0, limit) + '...';
    }
    return txt;
  }

  async function saveOrder(newOrder) {
    try {
      let response = await api.put(`/api/v1/projects/${project}/guides`, {guides: newOrder} );
      if (response?.ok === true) {

      }
    } catch (e) {
      if (e.statusCode === 404) {
        return setError('404')
      }
    }
  }

  function onSortEnd (oldIndex, newIndex) {
    let newGuides = arrayMove([...data.guides], oldIndex, newIndex);
    let newData = {
      ...data,
      guides: [...newGuides],
    }

    let newOrder = newData.guides.map((g, i) => {
      return {id: g.id, order: ++i};
    })
    setData(newData);
    saveOrder(newOrder);
  }

  function drawProgress (guide) {
    let progress = localStorage.getItem('progress_' + guide.id);
    if (progress) {
      progress = JSON.parse(progress);
    }
    return (
      progress ? (progress?.length === guide?.steps?.length ? <span style={{display:'block', margin: 7}} className="badge text-bg-success">Completed</span> : <span style={{display:'block', margin: 7}} className="badge text-bg-warning">In Progress {progress?.length}/{guide.steps.length}</span>) : null
    );
  }

  return (
    <>
    <HeaderNavbar/>  
    <ol className="breadcrumb navi">
      <li className="breadcrumb-item"><a href={`${config.prefixPath}`}>Home</a></li>
      <li className="breadcrumb-item active" aria-current="page">{data.title}</li>
    </ol>
    <img src={data.image || "https://placehold.co/2500x280"} className="projects-img card-img-top" alt="..."/>
    <h1>{data.title}</h1>
    <h2>{data.subTitle}</h2>
    <p>{data.description}</p>
    <div style={{paddingBottom: 14}}>
      <h2 style={{display: 'inline'}}>Guides</h2>
      {(isAuthenticated && userRoles.includes('admin')) ? <button style={{marginLeft: 12, marginTop: -12}} className="btn btn-sm btn-outline-dark" onClick={() => handleShowModal('new')}>Create Guide</button> :''}
    </div>  
    <div style={{marginBottom: 20}}>{drawTags()}</div>
    <SortableList onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)} style={{display: 'block', userSelect: 'none'}}>
    <div style={{marginBottom: 50}} className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4">

    {(!data.guides || data.guides.length === 0) && <div> No guides </div>}

    
      
    {(data?.guides || []).map((guide, index) => (
      
      <div className="col" key={index}>
      <SortableItem key={`sort-${index}`}>
        
        <div className="card text-center">
        {(isAuthenticated && userRoles.includes('admin') && !filterByTag) ? <SortableKnob>
          <div title="Grab to sort" className="sort-grab-guides"><FontAwesomeIcon icon={faArrowRightArrowLeft} /></div>
        </SortableKnob> : null}
          <a href={`${config.prefixPath}/projects/${project}/guides/${guide.slugURL}`} className="text-decoration-none text-reset">
            <Card.Img className="guides-img" variant="top" src={guide?.mainImage?.url || 'https://placehold.co/286x180' }/>
            
          <div className="card-body">
            <h5 className="card-title">{guide.title}</h5>
            {guide.published ? null : <span style={{display:'block', margin: 7}} className="badge text-bg-danger">Unpublished draft</span>}
            {drawProgress(guide)}
            <p className="card-text">{shortText(guide.description, 150)}</p>
            <small>Last updated {moment(guide.updateDate).fromNow()}.</small>
          </div>
          </a>
        </div>
        </SortableItem>
      </div>
      
       ))}
    
    </div> 
  </SortableList>
    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={showModal ? { display: 'block' } : { display: 'none' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{modalType === 'new' ? 'New Guide' : 'Edit Guide'}</h5>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" className="form-control" name="title" value={modalData.title} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea className="form-control" name="description" value={modalData.description} onChange={handleInputChange} />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {loading ? <Spinner animation="border" /> : 
              <>
                <button type="button" className="btn btn-sm btn-outline-dark" onClick={handleCloseModal}>Close</button>
                <button type="button" className="btn btn-sm btn-outline-dark" onClick={handleSubmit}>Save changes</button>
              </>}
            </div>
          </div>
        </div>
      </div> 
      <Footer/> 
    </>
  );
}

export default ProjectHome;