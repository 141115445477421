import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import HeaderNavbar from '../components/HeaderNavbar';
import Footer from '../components/Footer';
import Spinner from 'react-bootstrap/Spinner';
import config from "../config";
import api from "../lib/request.js";
import { useAuthContext } from '../contexts/AuthContext';

function ProjectsList() {
  const { isAuthenticated, userRoles } = useAuthContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState({
    title: '',
    subTitle: '',
    description: '',
  });

  async function fetchData() {
    try {
      const response = await api.get(`/api/v1/projects`);
      setData(response);
    } catch (e) {
      console.log('e===', e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleShowModal(type, data) {
    setModalType(type);
    setModalData(data || {
      title: '',
      subTitle: '',
      description: '',
    });
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  function handleInputChange(event) {
    setModalData({
      ...modalData,
      [event.target.name]: event.target.value,
    });
  }

  function handleFileChange(event) {
     const file = event.target.files[0];
     setModalData((prevData) => ({
       ...prevData,
       file: file,
     }));
   }

  async function deleteProject (project) {
    setLoading(true);
    try {
      const response = await api.delete(`/api/v1/projects/${project}`);
      setLoading(false);
      if (response.ok === true) {
         // Fetch data again
         fetchData();
         toast.success(`Project successfully deleted`);
       } else {
         if (response.error) {
           toast.error(response.message);
         }
       }
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
     }
   }

  async function handleSubmit(event) {
     // Prevent form being submitted through HTML
     event.preventDefault();
     setLoading(true);

     if (!modalData?.title.trim()) {
      alert('Title is required')
      setLoading(false);
      return;
     }

     // construct the URL based on the action
     const url = modalType === 'edit' 
       ? `/api/v1/projects/${modalData.slugURL}` 
       : `/api/v1/projects`;

     // choose method based on the action
     const method = modalType === 'edit' ? 'put' : 'post';

     // Create FormData
     const formData = new FormData();
     formData.append("title", modalData.title);
     formData.append("subTitle", modalData.subTitle);
     formData.append("description", modalData.description);
     
     // Check if a file has been selected and append it
     if (modalData.file) {
       formData.append("images", modalData.file);
     }

     const requestData = {
       method: method,
       url: url,
       data: formData,
       headers: { "Content-Type": "multipart/form-data" }
     };

     try {
       const response = await api(requestData);
       // Update the state and close the modal
       setLoading(false);
       if (response.ok === true) {
         // Fetch data again
         document.getElementById('theform').reset();
         fetchData();
         handleCloseModal();
         toast.success(`Project successfully ${modalType === 'edit' ? 'edited' : 'created'}`)
       } else {
         if (response.error) {
           toast.error(response.message);
         }
       }
     } catch (error) {
      setLoading(false);
      toast.error(error.toString());
     }
   }

   function getDraftCount(guides) {
    let draftTotal = 0;
    if (guides && guides.length) {
      draftTotal = guides.filter(g => g.published === false).length;
    }

    return (draftTotal ? <small>({draftTotal} draft{draftTotal === 1 ? '' : 's'})</small> :null);
   }

  if (!data) return null;

  return (
    <>
     <HeaderNavbar/>
      <ol className="breadcrumb navi">
        <li className="breadcrumb-item active"><a href={`${config.prefixPath}`}>Home</a></li>
      </ol>
      <h1 style={{display: 'inline'}} key="nav3">Projects</h1> <div style={{"marginTop": 9, "float": "inline-end"}}>{(isAuthenticated && userRoles.includes('admin')) ? <button key="nav2" className="btn btn-sm btn-outline-dark" onClick={() => handleShowModal('new')}>Create Project</button> :''}</div>
      <div className="row">
      <div className="col-12">
      {data.map((project, index) => (
        <>
          <div className="card-body card mb-3 text-decoration-none" key={`c-${index}`}>
            <a href={`${config.prefixPath}/projects/${project.slugURL}`} className="text-decoration-none" key={`a-${index}`}>
              <img src={project.image || "https://placehold.co/2500x280"} className="projects-img card-img-top" alt="..." key={`i-${index}`}/>
            </a>
            <a href={`${config.prefixPath}/projects/${project.slugURL}`} className="text-reset text-decoration-none" key={`a2-${index}`}>
              <h2 style={{marginTop: 12}} key={`h-${index}`} className="card-title">{project.title}</h2>
              <h3 className="card-text">{project.subTitle}</h3>
              <p className="card-text">{project.description}</p>
              <p style={{fontWeight: 'bold'}} className="card-text">{project?.guides?.length || 0} guide{`${(project?.guides?.length || 0) === 1 ? '':'s'}`} {(isAuthenticated && userRoles.includes('admin')) ? getDraftCount(project?.guides) : null}</p>

              <p className="card-text"><small className="text-body-secondary">{}</small></p>
            </a>
            {(isAuthenticated && userRoles.includes('admin')) ?
            <div key={`s-${index}`}>
            {loading ? <Spinner animation="border" /> :
              <div className="btn-group" key={`bg-${index}`}>
                <button key={`e-${index}`} className="btn btn-sm btn-outline-dark" onClick={() => handleShowModal('edit', project)}>Edit</button>
                <button key={`d-${index}`} className="btn btn-sm btn-outline-dark" onClick={() => deleteProject(project.slugURL)}>Delete</button>
              </div>
            }
            </div>
            : ''}
          </div>
        </>
      ))}
      </div>
      </div>
      <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={showModal ? { display: 'block' } : { display: 'none' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{modalType === 'new' ? 'New Project' : 'Edit Project'}</h5>
            </div>
            <div className="modal-body">
              <form id="theform" className="needs-validation">
                <div className="form-group">
                  <label>Title</label>
                  <input required type="text" className="form-control" name="title" value={modalData.title} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Subtitle</label>
                  <input type="text" className="form-control" name="subTitle" value={modalData.subTitle} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea className="form-control" name="description" value={modalData.description} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Image</label>
                  <input onChange={handleFileChange} className="form-control" name="images" type="file" id={`fileInput`} accept="image/*;capture=camera" multiple={false}/>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            {loading ? <Spinner animation="border" /> : <>
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={handleCloseModal}>Close</button>
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={handleSubmit}>Save changes</button>
              </>
              }
            </div>
          </div>
        </div>
      </div>

      <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
      <Footer/>
    </>
  );
}

export default ProjectsList;