import React from 'react';
import {Container, NavDropdown} from 'react-bootstrap';
import config from '../config';
import { useAuthContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'


function Footer({extraContent}) {
  const { isAuthenticated, userRoles } = useAuthContext();

  return (
    <>

    <div className="container">
  <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <div className="col-md-4 d-flex align-items-center">
      <a href="/" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
        <img style={{marginRight: 1}} src="/logo.svg" alt="Logo" width="30" height="30" className="d-inline-block align-text-top"/>
      </a>
      <span className="mb-3 mb-md-0 text-body-secondary">© 2024 StepWiseDocs, Inc</span>
    </div>

    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li className="ms-3"><a title="Say Hi!" target="_blank" className="text-body-secondary" href="https://github.com/koalazak/stepwisedocs"><FontAwesomeIcon size="2x" icon={faGithub}/></a></li>
    </ul>
  </footer>
</div>
    </>
  );
}

export default Footer;