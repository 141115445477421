import React, { useRef, useEffect, useState } from "react";
import TuiImageEditor from "tui-image-editor";


function ImageEditor(props) {
  const rootEl = useRef(null);
  const [src] = useState("");
  const [editor, setEditor] = useState();
  useEffect(() => {
    const editor = new TuiImageEditor(rootEl.current , {
      ...props
    });

   function drawArrow (event) {
      //event.preventDefault();
      event.stopPropagation();
      editor.startDrawingMode('LINE_DRAWING', {
         arrowType: {
            tail: 'chevron'
         },
         color: document.querySelectorAll('.color-picker-value')[2].style.backgroundColor,
         width: document.querySelectorAll('.tie-draw-range-value.tui-image-editor-range-value')[0].value
      });
    }

    const res = document.getElementsByClassName("tui-image-editor-header");
    res[0].classList.add("hidden");
    setEditor(editor); 
    const menuItems = document.querySelector('.tie-draw-line-select-button');
    // const menuItems = document.querySelector('.tui-image-editor-submenu-item');
    const htmlLoco = document.createElement('div');
    htmlLoco.className = 'tui-image-editor-button line';
    htmlLoco.innerHTML = `
                <div>
                    <svg class="svg_ic-submenu"><use xlink:href="#ic-icon-arrow" class="normal use-default"></use><use xlink:href="#ic-icon-arrow" class="active use-default"></use></svg>
                </div>
                <label>
                    Arrow
                </label>
            `;
    
    if (menuItems) {
      menuItems.appendChild(htmlLoco);
      htmlLoco.addEventListener('click', drawArrow);
    }
  }, []);

  const downloadImage = (image) => {
    if (image) {
      fetch(image)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = props.currentImage.split('/')[props.currentImage.split('/').length-1];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
    }
  };



  return (
    <>
      <div className="flex justify-around" style={{position: 'absolute', 'zIndex': 1000000000000, paddingTop: 22, paddingLeft: 4}}>
        <button className="btn btn-sm btn-outline-light" style={{marginTop: 8, display: 'block'}} onClick={() => { props.closeEditor() }}>Close</button>
        <button className="btn btn-sm btn-outline-light" style={{marginTop: 8, display: 'block'}} onClick={() => { downloadImage(editor?.toDataURL());}}>Download</button>
        <button className="btn btn-sm btn-outline-light" style={{marginTop: 8, display: 'block'}} onClick={() => { props.saveNewImage(editor?.toDataURL(), props.currentImage, props.step, props.stepTitle)}}>Save</button>
      </div>
      <img src={src} alt="" />
      <div ref={rootEl} />


      
    </>
  );
}

export default ImageEditor;