import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAuthContext } from '../contexts/AuthContext';
import Page404 from './404';
import api from "../lib/request.js";
import config from "../config.js";
import { Navigate } from 'react-router-dom';
import Footer from '../components/Footer';

function GuideHomeUpload() {
  const { isAuthenticated, userRoles } = useAuthContext();
  let { project, guide } = useParams();

  const [statusCode, setStatusCode] = useState(null);
  const [data, setData] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentStepTitle, setCurrentStepTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({complete: false});
  const [socketUrl] = useState(`${config.wsURL}/?d=0&project=${project}&guide=${guide}&remoteUploader=true`);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (m) => {
      console.log('onMessage', m);
    },
    shouldReconnect: (closeEvent) => true
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  async function fetchData() {
    try {
      const response = await api.get(`/api/v1/projects/${project}/guides/${guide}`);
      setData(response);
      setStatusCode(null);
    } catch (e) {
      console.log(e);
      if(e.statusCode === 404){
        setStatusCode('404');
      }
      setData(null);
    }
  }

  function handleChangeFile (e, step, stepTitle) {
    console.log('handler');
    const formData = new FormData();
    formData.append('step', step);
    formData.append('stepTitle', stepTitle);
    formData.append('remote', true);
    setLoading(true)
    if (e && e.target && e.target.files && e.target.files.length) {
      console.log('hay');
      for (let i = 0; i < e.target.files.length; i++) {
        console.log(e.target.files[i]);
        formData.append("files", e.target.files[i], e.target.files[i].name);
      }

      const requestData = {
        method: "post",
        url: `/api/v1/projects/${project}/guides/${guide}/upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      };

      api(requestData).then(res => {
        setLoading(false)
        console.log(res);
        e.target.value = '';
        if (res.ok) {
          // ok!
          setResult({complete: true, class: 'success', message: 'Upload complete!'});
        } else if (res.error) {
          setResult({complete: true, class: 'danger', message: res.message});
        }
      }).catch (err => {
        e.target.value = '';
        setLoading(false);
        console.log(err);
      });
    } else {
      setLoading(false);
    }
  }

  function showFileDialog(e) {
    setResult({complete: false});
    const fileElem = document.getElementById("fileInput");
    fileElem.click();
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log('lastJsonMessage', lastJsonMessage);
    if (lastJsonMessage !== null) {
      console.log('received Message!', lastJsonMessage);
      if (lastJsonMessage.cmd) {
        switch(lastJsonMessage.cmd) {
          case 'movedToStep':
            setCurrentStep(parseInt(lastJsonMessage.step));
            setCurrentStepTitle(lastJsonMessage.title);
            if (lastJsonMessage.step !== currentStep) {
              // TODO show pop up
            }
          break;
          default:
          break;
        }
      }
    }
  }, [lastJsonMessage]);

  if (statusCode === '404') {
    return (<Page404/>);
  }

  if (!data) return;

  function getGoBack() {
    return window.encodeURI(`/projects/${project}/guides/${guide}/remote-upload`);
  }

  if (!isAuthenticated) return (<Navigate to={`${config.prefixPath}/login?goback=${getGoBack()}`}/>)

  return (isAuthenticated && userRoles.includes('admin') &&
    <>
    <h1>{data.project.title}</h1>
    <h2>{data.project.subTitle}</h2>
    <h3>{data.guide.title}</h3>

     <div className="row">
      <div className="col-12">
        {(currentStep) ? <>
        <h3>Uploading Image to: <a href={`${config.prefixPath}/projects/${data.project.slugURL}/guides/${data.guide.slugURL}#step${currentStep}`}>Step {currentStep} - {currentStepTitle}</a></h3>
        <input type="file" id="fileInput" onChange={(e) => handleChangeFile(e, currentStep, currentStepTitle)} accept="image/*;capture=camera" multiple style={{display: 'none'}}/>
        
        
        {(loading) ? <Spinner animation="border" /> : <button onClick={showFileDialog} type="button" className="btn btn-sm btn-outline-dark">Upload Image(s)</button>}
        {(result.complete) ? <div style={{marginTop: 11}} className={`alert alert-${result.class}`} role="alert">{result.message}</div> : ''}

        </>: <h3>Select a step in main device.</h3>}
      </div>
    </div>
     <span onClick={() => sendJsonMessage({test: 'Helloooo from upload'})}>WebSocket Status: {connectionStatus}</span>
      <Footer/>
    </>
  );
}

export default GuideHomeUpload;