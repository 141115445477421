import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

function EditableDifficulty ({ name, value, index, onValueChange, path, contentStyle, editStyle, editable}) {
    
    const [edit, setEdit] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value || '1');

    useEffect(() => {
      if (edit) {
        document.getElementById(`${name}-${index}`).focus();
      }
    }, [edit, name, index]);

    const handleContentClick = (index, content) => {
      if (!editable) return;
      setEdit(true);
      setSelectedValue(content);
    };

    function handleChange(event) {
      setSelectedValue(event.target.value);
      setEdit(false);
      if (onValueChange) onValueChange(path, event.target.value);
    }

    const options = {
      '1': {
        text: 'Very Easy',
        className: 'difficulty-very-easy'
      },'2': {
        text: 'Easy',
        className: 'difficulty-easy'
      },'3': {
        text: 'Moderate',
        className: 'difficulty-moderate'
      },'4': {
        text: 'Hard',
        className: 'difficulty-hard'
      },'5': {
        text: 'Very Hard',
        className: 'difficulty-very-hard'
      }
    }

     return (
      ((edit && editable) ? (
        <Form.Select
          aria-label={options[selectedValue].text}
          id={`${name}-${index}`}
          key={`${name}-${index}`}
          value={selectedValue} 
          onChange={(e) => handleChange(e, index)}
          onBlur={(e) => handleChange(e, index)}
          style={{width: `11em`, display: 'inline-block', ...editStyle}}
        >
          <option value="1">Very Easy</option>
          <option value="2">Easy</option>
          <option value="3">Moderate</option>
          <option value="4">Hard</option>
          <option value="5">Very Hard</option>
        </Form.Select>
      ) : (
        <div className={options[selectedValue].className} style={{display: 'inline', cursor: editable ? "context-menu" : ""}} id={`difficulty-${name}-${index}`} onClick={() => handleContentClick(index, selectedValue)}>{options[selectedValue].text}</div>
      ))
    );
    /*

     <Form.Select aria-label="Default select example">
      <option>Open this select menu</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>

    return (
      (edit ? (
        <input 
          id={`${name}-${index}`}
          key={`${name}-${index}`}
          type="text" 
          value={text} 
          onChange={handleChange} 
          onBlur={(e) => handleEditableBlur(e, index)}
          onKeyPress={(e) => handleKeyPress(e, index)}
          style={{...editStyle}}
        />
      ) : (
        <div style={{cursor: "context-menu"}} id={`editable-${name}-${index}`} onClick={() => handleContentClick(index, text)}>{text}</div>
      ))
    );
    */
}

export default EditableDifficulty;