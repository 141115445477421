import React, { useState } from 'react';
import {Alert} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import config from '../config';
import HeaderNavbar from '../components/HeaderNavbar';
import Spinner from 'react-bootstrap/Spinner';
import Footer from '../components/Footer';

import api from '../lib/request';
import './Login.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const LoginPage = () => {
  const query = useQuery();
  const goback = query.get('goback');
  const externalError = query.get('externalError');

  const { isAuthenticated, login } = useAuthContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    setLoading(true)
    e.preventDefault();
    setError('');
    try {
      const response = await api.post(`/api/v1/auth/login`, { username, password });
      setLoading(false)
      setPassword('');
      if (response?.auth_token) {
        // localStorage.setItem('token', response.auth_token);
        const ok = await login(response.auth_token)
        if (ok) {
          const backto = goback ? `${config.siteURL}${config.prefixPath}${goback}` : `/`;
          window.location.href = backto;
        } else {
          setError('Unknown Error. Contact Support.');
          document.getElementById('password').focus();
        }
      } else if (response.error) {
        setError(response.message);
        document.getElementById('password').focus();
      } else {
        console.log(response);
      }
    } catch (err) {
      setLoading(false)
      setPassword('');
      if (err.error) {
        setError(err.message);
        document.getElementById('password').focus();
      }
    }
  };

  function getErrorMsg () {
    let displayError = '';
    if (error) {
      displayError = error;
    } else if (externalError === 'expired') {
      displayError = `Your session has expired, please log in again`;
    } else if (externalError === 'forbiden') {
      displayError = `You do not have permissions to operate over this URL. Need to login with a different user?`;
    } else if (externalError === 'unauthorized') {
      displayError = `Access denied.`;
    }
    if (displayError) {
      return (<Alert className="d-grid gap-2 mt-3" variant="danger">{displayError}</Alert>)
    }
  }

   return (
    <>
     <HeaderNavbar/>
     <div className="row">
     {isAuthenticated ? <h3> You are already logged</h3> : 
          <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleLogin}>
              <div className="Auth-form-content">
               <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10}}>
               <img src="/logo.svg" alt="Logo" width="100" height="100"/>
               </div>
                <h3 className="Auth-form-title">Sign In</h3>
                <div className="text-center">
                  Not registered yet? <a href="#">Sign Up</a>
                </div>
                <div className="form-group mt-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control mt-1"
                    placeholder="Enter email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    autoFocus
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Password</label>
                  <input
                    id="password"
                    type="password"
                    value={password}
                    className="form-control mt-1"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter password"
                  />
                </div>

                <div>
                {getErrorMsg()}
                </div>
               
                <div className="d-grid gap-2 mt-3">
                  {loading ? <Spinner animation="border" /> : <button type="submit" className="btn btn-primary">Submit</button>}
                </div>
                <p className="text-center mt-2">
                  Forgot <a href="#">password?</a>
                </p>
              </div>
            </form>
          </div>
          }
      </div>
       <Footer/>
      </>
    )
};

export default LoginPage;
