import React, { useState, useEffect, useRef, createContext, useContext } from "react";
import config from '../config';
import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTriangleExclamation, faCircle, faCircleInfo, faArrowRightArrowLeft, faList, faGaugeSimpleHigh, faTag, faPalette, faListCheck } from '@fortawesome/free-solid-svg-icons'
import {faCircleCheck as faCheck, faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import {Modal, Container, Form} from 'react-bootstrap';
import QRCode from "react-qr-code";
import Spinner from 'react-bootstrap/Spinner';
import 'tui-image-editor/dist/tui-image-editor.css';
import "tui-color-picker/dist/tui-color-picker.css";
import ImageEditor from '../components/ImageEditor';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import ReactMarkdown from 'react-markdown'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import arrayMove from "array-move";
import EditableTextArea from '../components/EditableTextArea';
import EditableInput from '../components/EditableInput';
import EditableDifficulty from '../components/EditableDifficulty';
import EditableTags from '../components/EditableTags';
import EditableImage from '../components/EditableImage';
import EditableMoveStep from '../components/EditableMoveStep';
import HeaderNavbar from '../components/HeaderNavbar';
import { useAuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import Page404 from './404';
import moment from 'moment';
import JSConfetti from 'js-confetti'
import * as markerjs2 from 'markerjs2';
import api from "../lib/request.js";

const EDITOR_ADDON = 2; // may be 1 (the horrible)

const jsConfetti = new JSConfetti()

function GuideHome() {
  const { isAuthenticated, userRoles } = useAuthContext();
  const navigate = useNavigate();
  let { project, guide } = useParams();

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeContentIndex, setActiveContentIndex] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [contentValue, setContentValue] = useState('');
  const [activeImages, setActiveImages] = useState([]);
  const [uploadingStep, setUploadingStep] = useState();
  const [uploadingStepTitle, setUploadingStepTitle] = useState();
  const [hoveredStepIndex, setHoveredStepIndex] = useState(null);
  const [editorDisplay, setEditorDisplay] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [autoSave, setAutoSave] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedFullImage, setSelectedFullImage] = useState('');
  const [connectedClients, setConnectedClients] = useState([]);
  const [guideProgress, setGuideProgress] = useState({});
  const [editorHeight, setEditorHeight] = useState(400);
  const intervalIdRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [socketUrl] = useState(`${config.wsURL}/?d=0&project=${project}&guide=${guide}`);

  // for debug
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl,{
    onMessage: (m) => {
      // console.log('onMessage', m);
    },
    shouldReconnect: (closeEvent) => true
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  // get guides data
  async function fetchData() {
    try {
      const response = await api.get(`/api/v1/projects/${project}/guides/${guide}`);
      setData(response);
      if (response?.ok) {
        setIsLoaded(true);
        setError(null);
        if (response?.guide?.id) {
          loadProgress(response.guide.id);
        }
        if(response.guide.steps && response.guide.steps[0]){
          uploadingHere(response.guide.steps[0])

          response.guide.steps.map((step, index) => {
            return setActiveImages(prevImages => {
              let newImages = [...prevImages];
              newImages[index] = response.guide.steps[index].images[0]?.url  || null;
              return newImages;
            });
          });
        } else {
          // TODO: create steps array and set state
          if (!response.guide.steps) {
            response.guide.steps = [];
          }
        }
      } else if (response?.statusCode === 404) {
        setError('404');
      } else {
        console.log(response);
        setData(null);
      }
    } catch (e) {
      if (e?.statusCode === 404) {
        return setError('404');
      }
      console.log(e);
      setData(null);
    }
  }

  useEffect(() => {
    if (isLoaded) {
      // Scroll to the anchor once data is loaded
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [isLoaded]);

  function save(hideToast) {
    setLoading(true);
    const requestData = {
      method: "put",
      url: `/api/v1/projects/${project}/guides/${guide}`,
      data: data
    };

    api(requestData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.ok) {
        // ok!
        setData(prevData => {
          prevData.guide.updateDate = new Date();
          return prevData;
        });
        if (!hideToast) toast.success(`Guide successfully saved!`)
        if (res.slugChanged) {
          // reload
          navigate(`${config.prefixPath}/projects/${project}/guides/${res.slugURL}`);
        }
        //setData(data);
      } else if (res.error) {
        alert(res.message);
        toast.error(res.message)
      }
    }).catch (e => {
      setLoading(false);
      console.log(e);
    });
  }

  function duplicateGuide () {
     setLoading(true);
     const newGuide = {...data.guide};
     newGuide.title = 'Copy of ' + newGuide.title;
      const requestData = {
        method: "post",
        url: `/api/v1/projects/${project}/guides`,
        data: newGuide
      };

    api(requestData).then(res => {
      setLoading(false);
      console.log(res);
      if (res.ok) {
        // ok!
        toast.success(`Guide successfully duplicated!`);
        window.open(`${config.prefixPath}/projects/${project}/guides/${res.createdSlugURL}`, '_blank')
        // navigate();
      } else if (res.error) {
        alert(res.message);
        toast.error(res.message)
      }
    }).catch (e => {
      setLoading(false);
      console.log(e);
    });
  }

  function deleteGuide() {
    if (window.confirm('Do you wanna permanently delete this Guide? Are you sure?')) {
      const requestData = {
        method: "delete",
        url: `/api/v1/projects/${project}/guides/${guide}`,
        data: data
      };

      api(requestData).then(res => {
        console.log(res);
        if (res.ok) {
          // ok!
          navigate(`${config.prefixPath}/projects/${project}`);
        } else if (res.error) {
          alert(res.message);
          toast.error(res.message)
        }
      }).catch (e => {
        console.log(e);
      });
    }
  }

  function togglePublish() {
    if (window.confirm(`Do you want to ${data.guide.published ? 'unpublish' : 'publish'} this guide?`)) {
      const editedData = {...data};
      editedData.guide.published = !editedData.guide.published;
      const requestData = {
        method: "put",
        url: `/api/v1/projects/${project}/guides/${guide}`,
        data: editedData
      };

      api(requestData).then(res => {
        console.log(res);
        if (res.ok) {
          // ok!
          setData(prevData => {
            const newData = {...prevData};
            return newData;
          });

        } else if (res.error) {
          alert(res.message);
          toast.error(res.message)
        }
      }).catch (e => {
        console.log(e);
      });
    }
  }

  function handleChangeFile (e, step, stepTitle, a) {
    console.log(e, a);
    setLoading(true);
    const formData = new FormData();
    formData.append('step', step);
    formData.append('stepTitle', stepTitle);
    if (e && e.target && e.target.files && e.target.files.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i], e.target.files[i].name);
      }
      const requestData = {
        method: "post",
        url: `/api/v1/projects/${project}/guides/${guide}/upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      };

      api(requestData).then(res => {
        setLoading(false);
        console.log(res);
        e.target.value = '';
        if (res.ok) {
          // ok!
        } else if (res.error) {
          alert(res.message);
        }
      }).catch (err => {
        e.target.value = '';
        setLoading(false);
        console.log(err);
      });
    } else {
      setLoading(false);
    }
  }

  function showFileDialog(e, stepPosition) {
    const fileElem = document.getElementById(`fileInput-${stepPosition}`);
    fileElem.click();
  }


  const addStep = (position, e) => {
      if (e) e.stopPropagation();
      const newItem = {
        position: position,
        title: 'Next step',
        description: 'complete here...',
        images: [] // init with an empty array
      };
      setData(prevData => {
        let newSteps = [...prevData.guide.steps];
        newSteps.splice(position - 1, 0, newItem);

        newSteps = newSteps.map((item, index) => {
          return { ...item, position: index + 1 };
        });

        return {
          ...prevData,
          guide: {
            ...prevData.guide,
            steps: newSteps
          }, 
        };
      });

    // update activeImages array and init the image url with null for new step
    setActiveImages(prevImages => {
      let newImages = [...prevImages];
      newImages.splice(position - 1, 0, null);
      return newImages;
    })

    uploadingHere(newItem);
  };

  const addImage = async (image, stepPosition) => {
    await setData(prevData => {
        let newSteps = [...prevData.guide.steps];

        // Find index of the step whose `position` matches `stepPosition`
        let stepIndex = newSteps.findIndex(step => step.position === stepPosition);

        // If such step is found, add the image
        if (stepIndex !== -1) {
            newSteps[stepIndex] = {
                ...newSteps[stepIndex],
                images: [...newSteps[stepIndex].images, image]
            };
        }
        return {
            ...prevData,
            guide: {
                ...prevData.guide,
                steps: newSteps
            },
        };
    })

    // Update active image of the step to the new image
    await setActiveImages(prevImages => {
      let newImages = [...prevImages];
      newImages[stepPosition - 1] = image.url;
      return newImages;
    })
  };

  const deleteImage = (stepIndex, imageURL) => {
    setData(prevData => {
      let newSteps = [...prevData.guide.steps];
      const imageIndex = newSteps[stepIndex].images.findIndex(i => i.url === imageURL);
      if (imageIndex > -1) {
       newSteps[stepIndex].images.splice(imageIndex, 1);
      }

      setActiveImages(prevImages => {
        let newImages = [...prevImages];
        newImages[stepIndex] = newSteps[stepIndex].images[imageIndex-1]?.url || newSteps[stepIndex].images[imageIndex]?.url || null; // reset active image to the first remaining image or null
        return newImages;
      });
      
      return {
        ...prevData,
        guide: {
          ...prevData.guide,
          steps: newSteps
        },
      };
    });
  }

  const removeStep = (position, e) => {
      e.stopPropagation();
      let prevIndex = data.guide.steps.findIndex(step => step.position === (position-1));
      let postIndex = data.guide.steps.findIndex(step => step.position === (position+1));
      setData(prevData => {
          let newSteps = [...prevData.guide.steps];
          // Removing the step
          newSteps.splice(position - 1, 1);
    
          // Recalculating steps' positions
          newSteps = newSteps.map((item, index) => {
              return { ...item, position: index + 1 };
          });
    
          return {
              ...prevData,
              guide: {
                  ...prevData.guide,
                  steps: newSteps
              },
          };
      });

      // Also remove the corresponding image
      setActiveImages(prevImages => {
          let newImages = [...prevImages];
          newImages.splice(position - 1, 1);
          return newImages;
      });

      if (data.guide.steps && data.guide.steps.length) {
        if (postIndex === -1 && prevIndex > -1) {
          uploadingHere(data.guide.steps[prevIndex]);
        } else if (prevIndex === -1 && postIndex > -1) {
          uploadingHere(data.guide.steps[postIndex-1]);
        } else if (prevIndex > -1 && postIndex > -1){
          uploadingHere(data.guide.steps[postIndex-1]);
        } else {
          uploadingHere({position: 0, title:''});
        }
        //
      } else {
        uploadingHere({position: 0, title:''});
      }
      return false;
  };

  function moveStep(path, newValue, originalPosition) {
    moveFromToPosition(originalPosition, newValue);
  }

  const moveFromToPosition = (from, to) => {
      setData(prevData => {
          let newSteps = [...prevData.guide.steps];
          const movedStep = newSteps.splice(from - 1, 1)[0]; // remove the element at index 'from - 1' and get it
          newSteps.splice(to - 1, 0, movedStep); // insert it back at index 'to - 1'

          // Recalculating steps' positions
          newSteps = newSteps.map((item, index) => {
              return { ...item, position: index + 1 };
          });
    
          return {
              ...prevData,
              guide: {
                  ...prevData.guide,
                  steps: newSteps
              },
          };
      });

      // Update image orders
      setActiveImages(prevImages => {
          let newImages = [...prevImages];
          const movedImage = newImages.splice(from - 1, 1)[0]; // remove the image at index 'from - 1'
          newImages.splice(to - 1, 0, movedImage); // insert it back at index 'to - 1'
          return newImages;
      });
  };


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
   if (data && autoSave && editMode) save(true);
  }, [data]);

  useEffect(() => {
    if (activeContentIndex !== null) {
      document.getElementById('content-' + activeContentIndex).focus();
    }
  }, [activeContentIndex]);

  useEffect(() => {
    if (activeIndex !== null) {
      document.getElementById('titleinput-' + activeIndex).focus();
    }
  }, [activeIndex]);

  const replaceImage = (image, stepPosition, oldImage) => {
    setData(prevData => {
      let newSteps = [...prevData.guide.steps];

      // Find index of the step whose `position` matches `stepPosition`
      let stepIndex = newSteps.findIndex(step => step.position === stepPosition);

      if (stepIndex !== -1) {
        const imageIndex = newSteps[stepIndex].images.findIndex(i => i.url === `/uploads/` + oldImage);
        if (imageIndex > -1) {
          newSteps[stepIndex].images[imageIndex].url = image.url;
        }
      }

      setEditorDisplay(null);

      return {
          ...prevData,
          guide: {
              ...prevData.guide,
              steps: newSteps
          },
      };
    })

    // Update active image
    setActiveImages(prevImages => {
      let newImages = [...prevImages];
      newImages[stepPosition - 1] = image.url;
      return newImages;
    })
  };

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      const expired = connectedClients.filter(item => (new Date().getTime() - 10000) > item.lastSeen.getTime());
      expired.map(e => {
        return removeClient(e.clientId);
      })
    }, 1000);

    return () => clearInterval(intervalIdRef.current); 
  }, [connectedClients]);

  function seenClient(id) {
    if (!id) return;
    const existIndex = connectedClients.findIndex(c => c.clientId === id)
    if (existIndex === -1){
      // add it
      const newConnectedClients = [...connectedClients, {clientId: id, lastSeen: new Date()}];
      setConnectedClients(newConnectedClients);
    } else {
      // update it
      const newConnectedClients = [...connectedClients]
      newConnectedClients[existIndex].lastSeen = new Date();
      setConnectedClients(newConnectedClients);
    }
  }

  function removeClient(id) {
    if (!id) return;
    const existIndex = connectedClients.findIndex(c => c.clientId === id)
    if (existIndex > -1) {
      const newConnectedClients = [...connectedClients]
      newConnectedClients.splice(existIndex, 1); 
      setConnectedClients(newConnectedClients);
    }
  }

  useEffect(() => {
    //console.log('lastJsonMessage', lastJsonMessage);
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.cmd) {
        switch(lastJsonMessage.cmd) {
          case 'imhere':
            sendJsonMessage({cmd: 'movedToStep', step: uploadingStep, title: uploadingStepTitle})
            toast.success('New device has been connected to upload images!')
            seenClient(lastJsonMessage.clientId);
          break;
          case 'ping':
            seenClient(lastJsonMessage.clientId);
          break;
          case 'bye':
            removeClient(lastJsonMessage.clientId);
          break;
          case 'newImg':
            lastJsonMessage.images.map(m =>{
              if (lastJsonMessage.edit) {
                replaceImage({url: lastJsonMessage.images[0]}, parseInt(lastJsonMessage.step), lastJsonMessage.oldPath);
                return toast.success(`Image successfully edited!`);
              }
              addImage({url: m}, parseInt(lastJsonMessage.step));
              return toast.success(`New image added to step: ${lastJsonMessage.stepTitle}!`)
            })
            console.log(data);
          break;
          default:
          break;
        }
      }
    }
  }, [lastJsonMessage]);

  const handleStepTitleClick = (index, title) => {
    if (!isEditable()) return;
        setActiveIndex(index);
        setInputValue(title);
  };

  const handleContentClick = (index, content, position) => {
    if (!isEditable()) return;
        setActiveContentIndex(index);
        setContentValue(content);
        let h = document.getElementById('renderedcontent-' + position).offsetHeight + 6;
        if (h < 399) h = 399;
        setEditorHeight(h);
  };

  // Function to handle editing content
  const handleInputChange = (event) => setInputValue(event.target.value);
  
  const handleContentChange = (event) => {
    setContentValue(event.target.value);
  };
  
  // Function to handle finishing editing title
  const handleBlur = (index) => {
        setData(prevData => {
            let newData = { ...prevData };
            newData.guide.steps[index].title = inputValue;
            return newData;
        });
        uploadingHere(data.guide.steps[index]);

        // Clear the active index
        setActiveIndex(null);
  };

  // Function to handle finishing editing content
  const handleContentBlur = (index) => {
        setData(prevData => {
            let newData = { ...prevData };
            newData.guide.steps[index].content = contentValue;
            return newData;
        });
        uploadingHere(data.guide.steps[index]);

        // Clear the active index
        setActiveContentIndex(null);
  };

  const handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleBlur(index);
    }
  }

  const handleImageClick = (imageUrl, index) => {
    setActiveImages(prevImages => {
      let newImages = [...prevImages];
      newImages[index] = imageUrl;
      return newImages;
    });
  };

  function uploadingHere(step, e) {
    if (e) return;
    setUploadingStep(step.position);
    setUploadingStepTitle(step.title);
    sendJsonMessage({cmd: 'movedToStep', step: step.position, title: step.title});
  }

  function closeEditor() {
    setEditorDisplay(null)
  }

  function saveNewImage (newImage, oldPath, step, stepTitle) {
    const fileName = oldPath.split('/')[oldPath.split('/').length-1];
      setLoading(true);
      const formData = new FormData();
      formData.append('step', step);
      formData.append('stepTitle', stepTitle);
      formData.append('edit', true);
      formData.append('oldPath', fileName);
      if (newImage) {
        fetch(newImage).then(response => response.blob()).then(blob => {
          formData.append("files", blob, fileName);

          const requestData = {
            method: "post",
            url: `/api/v1/projects/${project}/guides/${guide}/upload`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
          };

          api(requestData).then(res => {
            setLoading(false);
            console.log(res);
            if (res.ok) {
              // ok!
            } else if (res.error) {
              alert(res.message);
            }
          }).catch (e => {
            setLoading(false);
            console.log(e);
          });
        });
      } else {
        setLoading(false);
      }
  }

  const handleFullImageClick = (image) => {
    setSelectedFullImage(image);
    setShowImageModal(true);
  };

  const handleImageCloseModal = () => {
    setShowImageModal(false);
  };

  function updateNestedProperty(obj, path, value) {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const deepRef = keys.reduce((o, key) => (o[key] = o[key] || {}), obj);
    deepRef[lastKey] = value;
    return { ...obj };
  }

  function updateState(path, newValue) {
    // console.log(`updateNestedProperty======`, updateNestedProperty({ ...data }, path, newValue) )
    setData(prevData => updateNestedProperty({ ...prevData }, path, newValue));
  }

  function isEditable() {
    return editMode && isAuthenticated && userRoles.includes('admin');
  }

  function getUploaderURL() {
    return `${config.siteURL}${config.prefixPath}/projects/${data.project.slugURL}/guides/${data.guide.slugURL}/remote-upload`;
  }

  async function copyURL () {
    try {
        await navigator.clipboard.writeText(getUploaderURL());
        alert("URL copied! Share it with the person who will upload the images.");
    } catch (err) {
        alert('Error copying URL, please copy it manually and share it.');
    }
  }

  const ListTypeContext = createContext('ul');

  function CustomListItem(props) {
    const listType = useContext(ListTypeContext); // Consume the context to get the list type
    const insideOL = listType === 'ol';
    // console.log(listType);
    // console.log(props.node.parentNode);
    let children = props.children;
    // console.log(children)
    let color;
    let text;
    // Map the colors to FontAwesomeIcon icons
    const colorIconMap = {
      blue: faCircle,
      red: faCircle,
      pink: faCircle,
      black: faCircle,
      green: faCircle,
      violet: faCircle,
      orange: faCircle,
      yellow: faCircle,
      lightblue: faCircle,
      alert: faTriangleExclamation,
      info: faCircleInfo,
    };

    let hexColor = '';

    
    if (typeof children === 'string') {
      [, color, text] = children.match(/^(\S+)\s+(.*)$/) || [];
      if (!colorIconMap[color]) {
        //isHexColor = /^#[0-9A-Fa-f]{6}$/.test(color);
        if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
          hexColor = color;
        } else {
          text = children;
          color = 'black';
        }
      }
    } else {
      [, color, text] = (children && children[0] || '').match(/^(\S+)\s+(.*)$/) || [];
      if (!colorIconMap[color]) {
        if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
          hexColor = color;
        } else {
          text = (children && children[0]) || '';
          color = 'black';
        }
      }
    }


    const childText = React.Children.toArray(children);

    const colorClassMap = {
      alert: 'red',
      info: 'info'
    }

    if (typeof children !== 'string') {
      const textComponents = childText.slice(1); // Exclude the color term
      //console.log('textComponents', textComponents);
      text = <>{text}{textComponents}</>;
    }

    return (
      <li>
            {!insideOL && (
              hexColor ? (<FontAwesomeIcon style={{color: hexColor}} className={`bullet-dynamic`} icon={faCircle} />) : (
                <FontAwesomeIcon className={`bullet-${colorClassMap[color] || color}`} icon={colorIconMap[color]} />)
            )}
            <span className={`step-text${insideOL ? '-ol' : ''}${colorClassMap[color] ? '-' + colorClassMap[color] : ''}`}>{text}</span>
        </li>
    );
  }

  function MyMarkdownComponent({ content, editable }) {
    return (
      <ReactMarkdown 
        components={{ 
          li: CustomListItem, 
          ul: ({ node, ...props }) => (
                    <ListTypeContext.Provider value="ul">
                        <ul className="step-items" {...props} />
                    </ListTypeContext.Provider>
                ),
                ol: ({ node, ...props }) => (
                    <ListTypeContext.Provider value="ol">
                        <ol className="step-itemso" {...props} />
                    </ListTypeContext.Provider>
                )
        }} 
      >
        {content}
      </ReactMarkdown>
    );
  }

  function CenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload Image from a different Device
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
            <div className="row">
              <div className="col-12" style={{textAlign: "center"}}>
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                    value={getUploaderURL()}
                    viewBox={`0 0 256 256`}
                  />
                <h4 style={{marginTop: 10}}>Scan the QR to open the uploader in your external Device</h4>
                <div>
                  <span>Or click this URL to copy it and share:</span>
                </div>
                
                  <span alt="Click to Copy" className="copyText" onClick={(e) => {copyURL()}}>{getUploaderURL()}</span>
                
                <h3 style={{marginTop: 10}}>Connected Clients: {connectedClients.length}</h3>
              </div>
            </div>

            <div className="row">
             <div className="col-12">
             </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-dark" onClick={props.onHide}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }

  const [activeHash, setActiveHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setActiveHash(window.location.hash);
    };
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  if (error === '404') return (<Page404/>);
  if (!data) return;

  function onSortEnd (oldIndex, newIndex, stepIndex) {
    //console.log('oldIndex', oldIndex);
    //console.log('newIndex', newIndex);
    //console.log('stepIndex', stepIndex);

    setData(prevData => {
      let newSteps = [...prevData.guide.steps];
      // console.log('antes', newSteps[stepIndex].images);
      newSteps[stepIndex].images = arrayMove(newSteps[stepIndex].images, oldIndex, newIndex)
      // console.log('despues', newSteps[stepIndex].images);

      return {
        ...prevData,
        guide: {
          ...prevData.guide,
          steps: newSteps
        },
      };
    });

  }

  function loadProgress (id) {
    let currentProgress = localStorage.getItem('progress_' + id);
    if (currentProgress) {
      setGuideProgress({steps: JSON.parse(currentProgress), tracking: true});
    }
  }

  function startTracking () {
    let currentProgress = localStorage.getItem('progress_' + data.guide.id);
    if (!currentProgress) {
      currentProgress = [];
      localStorage.setItem('progress_' + data.guide.id, JSON.stringify(currentProgress));
    } else {
      currentProgress = JSON.parse(currentProgress);
    }
    setGuideProgress({steps: currentProgress, tracking: true});
  }

  function checkAllTracking () {
    let positions = data.guide.steps.map(s => s.position);
    localStorage.setItem('progress_' + data.guide.id, JSON.stringify(positions));
    setGuideProgress({steps: positions, tracking: true});
    jsConfetti.addConfetti({
      emojis: ['🦄', '⭐️', '🎊', '💥'],
      // emojiSize: 100,
      confettiNumber: 100,
    });
  }

  function resetTracking() {
    localStorage.removeItem('progress_' + data.guide.id);
    setGuideProgress({});
  }

  function checkStep (position) {
    if (guideProgress?.steps.includes(position)) {
      // sacar
      let newArray = guideProgress?.steps.filter(item => item !== position);
      setGuideProgress(prevProgress => {
        return {
          ...prevProgress,
          steps: newArray
        };
      });
      localStorage.setItem('progress_' + data.guide.id, JSON.stringify(newArray));
    } else {
      // add
      jsConfetti.addConfetti()
      setGuideProgress(prevProgress => {
        return {
          ...prevProgress,
          steps: [...prevProgress.steps, position]
        };
      });
      const newProgress = [...guideProgress.steps, position];

      localStorage.setItem('progress_' + data.guide.id, JSON.stringify(newProgress));
      if (newProgress.length === data.guide.steps.length) {
        jsConfetti.addConfetti({
          emojis: ['🦄', '⭐️', '🎊', '💥'],
          // emojiSize: 100,
          confettiNumber: 100,
        });
      }
    }
    
  }

  function getProgress () {
    return (
        guideProgress?.tracking ? <span>{guideProgress?.steps.length}/{data.guide.steps.length} {guideProgress?.steps.length === data.guide.steps.length ? <span className="badge text-bg-success">Completed</span> : <span className="badge text-bg-warning">In Progress</span>} <a style={{cursor: 'pointer'}} className="text-decoration-none" onClick={()=> resetTracking()}>[Reset]</a> <a style={{cursor: 'pointer'}} className="text-decoration-none" onClick={()=> checkAllTracking()}>[Check All]</a></span>  : <a style={{cursor: 'pointer'}} className="text-decoration-none" onClick={()=> startTracking()}>[Start Tracking]</a> 
      )
  }

  async function showAddVideo (e, stepPosition) {
   let videoURL = prompt("Paste the Youtube video URL here:");
   if (videoURL && videoURL.trim() && (videoURL.trim().indexOf('https://www.youtube.com/') === 0 || videoURL.trim().indexOf('https://youtu.be/') === 0)) {
    const newVideo = {
      url: videoURL.trim(),
      type: 'video'
    };

    await setData(prevData => {
        let newSteps = [...prevData.guide.steps];

        // Find index of the step whose `position` matches `stepPosition`
        let stepIndex = newSteps.findIndex(step => step.position === stepPosition);

        // If such step is found, add the image
        if (stepIndex !== -1) {
            newSteps[stepIndex] = {
                ...newSteps[stepIndex],
                images: [...newSteps[stepIndex].images, newVideo]
            };
        }
        return {
            ...prevData,
            guide: {
                ...prevData.guide,
                steps: newSteps
            },
        };
    })

    // Update active image of the step to the new image
   
    await setActiveImages(prevImages => {
      let newImages = [...prevImages];
      newImages[stepPosition - 1] = newVideo.url;
      return newImages;
    })

   } else {
    alert("Invalid URL. Must be a Youtube URL.");
   }
  }

function getYouTubeVideoID(url) {
  try {
    let parsedUrl = new URL(url);
    
    // Check if the URL has a 'v' query parameter
    let videoID = parsedUrl.searchParams.get("v");
    
    if (videoID) {
      return videoID;
    }
    
    // Extract pathname and split it by '/'
    let pathnameSegments = parsedUrl.pathname.split("/");

    // Check if it's a short URL (youtube.com/shorts)
    if (pathnameSegments[1] === "shorts" && pathnameSegments.length > 2) {
      return pathnameSegments[2];
    }
    
    // Assume it's a short URL format if no 'v' parameter and not a "shorts" URL
    if (pathnameSegments.length > 1) {
      return pathnameSegments[1];
    }
    
    return null; // No valid video ID found
  } catch (e) {
    console.error(e);
    return null;
  }
}

  function getVideoThumb (url, size) {
    const vidId = getYouTubeVideoID(url);
    const thumbURL = `https://img.youtube.com/vi/${vidId}/${size}.jpg`;
    return thumbURL;
  }

  function getVideoPlayer (url) {
    const id = getYouTubeVideoID(url);
    return (<iframe style={{maxHeight: 383, width: 'inherit', maxWidth: 'inherit', height: 370}} src={`https://www.youtube.com/embed/${id}`} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)
  }

  function drawVideoThumb(stepImage, indexImage, index, step) {
    const thumbURL = getVideoThumb(stepImage.url, 'hqdefault');
    return (
      <img alt="" variant="top" style={{userSelect: 'none', 'width': 158, cursor: 'pointer', objectFit: 'cover', maxHeight: 125}} src={thumbURL} key={indexImage}
        className={activeImages[index] === stepImage.url ? 'border-tumb' : ''} 
        onClick={() => handleImageClick(stepImage.url, index)}
      />
      );
  }

  function isVideo (url) {
    return (url.indexOf('https://www.youtube.com/') === 0 || url.indexOf('https://youtu.be/') === 0);
  }

  function newEditor (e, step, stepTitle) {
    e.stopPropagation();
    e.preventDefault();
    const markerArea = new markerjs2.MarkerArea(e.target);
    markerArea.settings.displayMode = 'popup';
    markerArea.settings.popupMargin = 100;
    markerArea.settings.defaultStrokeWidth = 5;
    markerArea.renderImageQuality = 1;
    markerArea.settings.defaultColorSet = ['#515ce6', '#feff00', '#ff4040', '#000000', '#FFFFFF', '#03bd9e', '#ff5583', '#ffbb3b', '#00a9ff', '#9e5fff', '#03ff00', '#feaaff'];
    markerArea.addEventListener("render", (event) => {
      saveNewImage (event.dataUrl, e.target.src, step, stepTitle);
      // e.target.src = event.dataUrl
    });
    markerArea.show();

  }

  function GuidePagination ({data}) {
    let prevGuide = '';
    let nextGuide = '';
    let prevFlag = true;
    data?.guides.map((guide, index) => {
      if (guide.id === data?.guide?.id) {
        // flit
        prevFlag = false;
      } else {
         if (prevFlag) {
          prevGuide = guide;
        } else {
          if (nextGuide === '') nextGuide = guide;
        }
      }
    });
    return (<div className="col-12 text-center">
              {prevGuide ? <div style={{float: 'left', display: 'inline', margin: 10}}><a className="text-decoration-none" href={`${config.prefixPath}/projects/${data.project.slugURL}/guides/${prevGuide.slugURL}`}>« {prevGuide.title}</a></div> : null}
              {nextGuide ? <div style={{float: 'right', display: 'inline', margin: 10}}><a className="text-decoration-none" href={`${config.prefixPath}/projects/${data.project.slugURL}/guides/${nextGuide.slugURL}`}>{nextGuide.title} »</a></div> : null}
            </div>)
  }

  return (
    <>
    
      <HeaderNavbar extraUserContent = {
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label={`Edit mode`}
          onChange={(e) => {setEditMode(e.target.checked);}}
          style={{cursor: 'pointer', float: 'left', display: 'inline', marginRight: 28}}
          />
      } extraContent={
        <>
        {editMode ? <button className="btn btn-sm btn-outline-dark" onClick={()=> save()}>Save guide {autoSave ? <small style={{}}>(autosave is on)</small> : '' } {loading? <Spinner size="sm" animation="border" /> : ''}</button> : ''}
        {data.guide.published ? '' : <small style={{color: 'red'}}>This guide is not published yet</small>}
        
        </>
      }/>
      <ol className="breadcrumb navi">
        <li className="breadcrumb-item"><a href={`${config.prefixPath}`}>Home</a></li>
        <li className="breadcrumb-item"><a href={`${config.prefixPath}/projects/${data.project.slugURL}`}>{data.project.title}</a></li>
        <li className="breadcrumb-item active" aria-current="page">{data.guide.title}</li>
      </ol>

      <h1><a href={`${config.prefixPath}/projects/${data.project.slugURL}`} className="text-reset text-decoration-none">{data.project.title}</a></h1>
      <h2><a href={`${config.prefixPath}/projects/${data.project.slugURL}`} className="text-reset text-decoration-none">{data.project.subTitle}</a></h2>
      <h3><EditableInput editable={isEditable()} name='titleDescription' editStyle={{}} value={data.guide.title} index={0} onValueChange={updateState} path={'guide.title'}/></h3>

      <div className="row">
        <div className="col-12 col-md-3 guide-nav-container">
          <div id="navid" className="row guide-nav">
            <ul>
              <li className="guide-nav-project">{data.project.title}</li>
              {data.guides.map((guideNav, indexGuideNav) => (
                <React.Fragment key={indexGuideNav}>
                  {guideNav.id === data.guide.id ? <li className="guide-nav-guide"><a href="#top" className="text-decoration-none text-reset">{guideNav.title}</a></li> : <li className="guide-nav-guide guide-nav-guide-other"><a href={`${config.prefixPath}/projects/${data.project.slugURL}/guides/${guideNav.slugURL}`}>{guideNav.title}</a></li>}

                  {data.guide?.steps && guideNav.id === data.guide.id ? data.guide.steps.map((stepnav, indexnav) => (
                    <li key={indexnav} className={`guide-nav-step${activeHash === `#step${stepnav.position}` ? ' active-nav' : ''}`}>
                      <a href={`#step${stepnav.position}`}> {stepnav.position}. {stepnav.title} {guideProgress?.tracking ? (guideProgress.steps.includes(stepnav.position) ? <FontAwesomeIcon icon={faCheck}/> : null) : null}</a>
                    </li>
                  )) : null}


               </React.Fragment>
               ))}
            </ul>
          </div>

        </div>
        <div className="col-12 col-md-9">

      <div className="row">
        <div className="col-12 col-md-4 order-1 order-md-1">
          <div className="row">
          <EditableImage editable={isEditable()} value={data.guide.mainImage} index={0} onValueChange={updateState} path={'guide.mainImage'} multiple={false} uploadURL={`/api/v1/projects/${project}/guides/${guide}/upload`}/>
          </div>
        </div>
        <div className="col-12 col-md-8 order-2 order-md-2">
          <ul className="list-group">
            {isEditable() && 
              <li className="list-group-item"><div className="guide-prop" style={{display: 'inline'}}>Admin Actions: </div>
                <div style={{display: 'inline'}}>
                  <div className="btn-group">
                    <button className="btn alert-btn btn-sm btn-outline-dark" onClick={()=> deleteGuide()}>Delete</button>
                    <button className={`btn btn-sm btn-outline-dark${data.guide.published ? ' alert-btn' : ' ok-btn'}`} onClick={()=> togglePublish()}>{data.guide.published ? 'Unpublish' : 'Publish'}</button>
                    <button className={`btn btn-sm btn-outline-dark`} onClick={()=> duplicateGuide()}>Duplicate</button>
                    
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="autosave-switch"
                      label={`Auto Save`}
                      onChange={(e) => {setAutoSave(e.target.checked);}}
                      checked={autoSave}
                      style={{cursor: 'pointer', marginLeft: 12, marginTop: 3}}
                      />
                  </div>
                </div>
              </li>}
              <li className="list-group-item"><div className="guide-prop" style={{display: 'inline'}}><FontAwesomeIcon icon={faGaugeSimpleHigh}/> Difficulty: </div><div style={{display: 'inline'}}><EditableDifficulty editable={isEditable()} name='difficulty' contentStyle= {{}} editStyle={{}} value={data.guide.difficulty} index={0} onValueChange={updateState} path={'guide.difficulty'}/></div></li>
              <li className="list-group-item"><div className="guide-prop"style={{display: 'inline'}}><FontAwesomeIcon icon={faList}/> Steps: </div><div style={{display: 'inline'}}> {data.guide.steps?.length || 0}</div></li>
              <li className="list-group-item"><div className="guide-prop"style={{display: 'inline'}}><FontAwesomeIcon icon={faListCheck}/> Progress: </div><div style={{display: 'inline'}}> {getProgress()}</div></li>
              <li className="list-group-item"><div className="guide-prop"style={{display: 'inline'}}><FontAwesomeIcon icon={faTag}/>Tags: </div><div style={{display: 'inline'}}><EditableTags editable={isEditable()} name='tags' editStyle={{}} value={data.guide.tags} index={0} onValueChange={updateState} path={'guide.tags'} linkPrefix={`${config.prefixPath}/projects/${data.project.slugURL}`}/></div></li>
              <li className="list-group-item"><div className="guide-prop"><FontAwesomeIcon icon={faPalette}/> What you need:</div><div><EditableTextArea editable={isEditable()} name='guidewhatyouneed' contentStyle= {{border: '1px solid #c3c3c3', borderRadius: 5, margin: 6, padding: 10}} editStyle={{width: '100%', height:100}} value={data.guide.whatyouneed} index={0} onValueChange={updateState} path={'guide.whatyouneed'}/></div></li>
          </ul>
          <small style={{float: 'right'}}>Last updated {moment(data.guide.updateDate).fromNow()}.</small>
          {editMode ? null: <div style={{height: 63, display: 'inline-block'}}></div>}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h4>Description</h4>
          <div><EditableTextArea editable={isEditable()} name='guideDescription' editStyle={{width: '100%', height:100}} value={data.guide.description} index={0} onValueChange={updateState} path={'guide.description'}/></div>
        </div>
      </div>

      <CenteredModal show={modalShow} onHide={() => setModalShow(false)}/>

      <Modal
        show={showImageModal}
        onHide={handleImageCloseModal}
        dialogClassName="modal-fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <img
            src={selectedFullImage}
            alt="Full-view"
            className="img-fluid"
            style={{ maxHeight: '90vh' }}
          />
        </Modal.Body>
      </Modal> 

      <div className="row">
                   {isEditable() ? <div className="hr-with-button-top"><button onClick={(e) => addStep(1)} type="button" className="btn btn-sm btn-outline-dark">Insert Step here</button></div> : <div className="align-items-center"><hr className="styled-hr-top"/></div>}

      </div>

      {(data.guide?.steps || []).map((step, index) => (
        <div className="row" key={index}>

          <div className="col-12" onClick={(e) => uploadingHere(step)}>
          <div style={{height: 39}}>
            <h3 className="anchor-top" id={`step${step.position}`} style={{fontWeight: 850, display: 'inline', marginBottom:10}}>
              {guideProgress?.tracking ? (guideProgress.steps.includes(step.position) ? <FontAwesomeIcon onClick={(e) => checkStep(step.position)} icon={faSquareCheck}/> : <FontAwesomeIcon onClick={(e) => checkStep(step.position)} icon={faSquare}/>) : null} &nbsp; <a className="text-decoration-none" href={`#step${step.position}`}>Step {step.position}</a> &nbsp;&nbsp; 

              {(activeIndex === index && isEditable()) ? (
                <input 
                  id={`titleinput-${index}`}
                  key={`titleinput-${index}`}
                  type="text" 
                  value={inputValue} 
                  onChange={handleInputChange} 
                  onBlur={() => handleBlur(index)}
                  onKeyPress={(event) => handleKeyPress(event, index)}
                  style={{fontWeight: "normal"}}
                />
              ) : (
                <small style={{cursor: isEditable() ? "context-menu" : "", fontWeight: "normal"}} id={`title-${step.position}`} onClick={() => handleStepTitleClick(index, step.title)}>{step.title}</small>
              )}
            </h3>
            {isEditable() ? 
              <div style={{float:'right', top: 0}} className="btn-group">
                <button className="btn btn-sm btn-outline-dark" onClick={(e) => {removeStep(step.position, e); return false;}}>delete</button>
                <EditableMoveStep value={step.position} name="moveto" index={index} onValueChange={moveStep}/>
              </div> : <div style={{display: 'inline-flex', height: 3}}></div>
            }
            </div>
            <div style={{paddingLeft: 14, paddingRight: 14}} className="row">
              <div className="col-12 col-lg-6 order-1 order-lg-1 imgarea" style={{paddingTop: 15}}>
                <div className="row" key={index}>
                  <div className="col-12" style={{paddingTop: 15, height: 400, textAlign:'center', position:"relative", border: "1px solid"}}
                    onMouseEnter={() => setHoveredStepIndex(index)}
                    onMouseLeave={() => setHoveredStepIndex(null)}>
                      {!!activeImages[index] && (editorDisplay !== step.position ?
                        (<>
                          {index === hoveredStepIndex && isEditable() && (
                            <div className="btn-group" style={{position: 'absolute', top: 0, right: 0, margin: 5}}>
                              <button className="btn btn-sm btn-dark" onClick={(e) => deleteImage(index, activeImages[index])}>Delete</button>
                              {isVideo(activeImages[index]) ? null : (EDITOR_ADDON === 1 && <button className="btn btn-sm btn-dark" onClick={(e) => setEditorDisplay(step.position)}>Edit</button>)}
                            </div>
                          )}
                          {isVideo(activeImages[index]) ? getVideoPlayer(activeImages[index]) : <img onClick={(e) => { if (isEditable() && EDITOR_ADDON === 2) {newEditor(e, step.position, step.title)} else {handleFullImageClick(e.target.src)} return false;}} variant="top" style={{cursor: 'pointer', objectFit: 'cover', maxHeight: 383, width: 'auto', maxWidth: 'inherit'}} src={activeImages[index]} key={index}/>}
                        </>) 
                      : 
                        (isEditable() &&
                          <ImageEditor key={activeImages[index]}
                            includeUI={{
                              loadImage: {
                                path: activeImages[index],
                                name: 'SampleImage',
                              },
                              menu: ['shape', 'draw', 'text', 'icon'],
                              initMenu: 'draw',
                              uiSize: {
                                width: '1000px',
                                height: '700px',
                              },
                              menuBarPosition: 'bottom',
                            }}
                            cssMaxHeight={500}
                            cssMaxWidth={700}
                            selectionStyle={{
                              cornerSize: 20,
                              rotatingPointOffset: 70,
                            }}
                            usageStatistics={false}
                            closeEditor={closeEditor}
                            saveNewImage={saveNewImage}
                            currentImage={activeImages[index]}
                            step={step.position}
                            stepTitle={step.title}
                          />
                        )
                      )}
                  </div>
                </div>
                <br/>
                <div className="row">
                  {!step.images.length && <div></div>}
                  <SortableList onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, index)} className="imglist">
                    {step.images.map((stepImage, indexImage) => (
                      isEditable() ? <SortableItem key={`sort-${index}-${indexImage}`}>
                      <div className="imgitem">
                      {isEditable() && <SortableKnob>
                        <div title="Grab to sort" className="sort-grab-thumb"><FontAwesomeIcon icon={faArrowRightArrowLeft} /></div>
                        </SortableKnob>
                      }
                        {stepImage.type !== 'video' ? <img alt="" variant="top" style={{userSelect: 'none', 'width': 158, cursor: 'pointer', objectFit: 'cover', maxHeight: 125}} src={stepImage.url} key={indexImage}
                          className={activeImages[index] === stepImage.url ? 'border-tumb' : ''} 
                          onClick={() => handleImageClick(stepImage.url, index)}
                        /> : drawVideoThumb(stepImage, indexImage, index, step)}
                        </div>
                      </SortableItem> : (stepImage.type !== 'video' ? <img alt="" variant="top" style={{userSelect: 'none', 'width': 158, cursor: 'pointer', objectFit: 'cover', maxHeight: 125}} src={stepImage.url} key={indexImage}
                          className={activeImages[index] === stepImage.url ? 'border-tumb' : ''} 
                          onClick={() => handleImageClick(stepImage.url, index)}
                        /> : drawVideoThumb(stepImage, indexImage, index, step))
                    ))}
                  </SortableList>
                </div>
                <div className="row">
                  <div style={{paddingLeft: 1}} className="col-12">
                    {isEditable() && <input type="file" id={`fileInput-${step.position}`} onChange={(e, a) => handleChangeFile(e, step.position, step.title, a)} accept="image/*;capture=camera" multiple style={{display: 'none'}}/>}
                    {isEditable() ? 
                      <div className="btn-group" style={{marginTop: 10}}>
                        {(loading) ? <Spinner style={{height: 31}} animation="border" /> : <button onClick={(e) => showFileDialog(e, step.position)} type="button" className="btn btn-sm btn-outline-dark">Upload Image</button>}
                        <button onClick={() => setModalShow(true)} type="button" className="btn btn-sm btn-outline-dark">Upload from different Device ({connectedClients.length})</button>
                        <button onClick={(e) => showAddVideo(e, step.position)} type="button" className="btn btn-sm btn-outline-dark">Add Video</button>
                      </div>
                    : <div style={{height: 41}}></div>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 order-2 order-lg-2">
                <div key={`stepContent${index}`} style={{paddingTop: 21}}>
                  {(activeContentIndex === index && isEditable()) ? (
                    <textarea 
                      id={`content-${index}`}
                      key={`content-${index}`}
                      onChange={handleContentChange} 
                      onBlur={() => handleContentBlur(index)}
                      value={contentValue}
                      style={{marginTop: -5, width: '100%', height: editorHeight}}
                    />
                  ) : (
                    <div style={{cursor: isEditable() ? "context-menu" : "", marginTop: -9}} id={`renderedcontent-${step.position}`} onClick={(e) => handleContentClick(index, step.content, step.position)}>{(step.content ? <MyMarkdownComponent editable={isEditable()} content={step.content}/> : (isEditable() ? 'Click here to edit step content' : ''))}</div>
                  )}
                </div>
              </div>
            </div>
            
            
            {isEditable() ? <div className="hr-with-button"><button style={{marginBottom: 10}} onClick={(e) => addStep(step.position + 1, e)} type="button" className="btn btn-sm btn-outline-dark">Insert Step here</button></div> : <div className="align-items-center"><hr className="styled-hr"/></div>}
            

          </div>
        </div>
      ))}
    <GuidePagination data={data}/>
</div>
</div>
      {isEditable() && <div onClick={() => {sendJsonMessage({test: 'Helloooo'}); toast('Here is your toast.'); }}>WebSocket Status: {connectionStatus}. Env: {config.env}</div>}
      <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
       <Footer/>
    </>
  );
}

export default GuideHome;