import React, { useState, useEffect } from 'react';

function EditableMoveStep ({ name, value, index, onValueChange, path, contentStyle, editStyle}) {
    
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState(value);
    const [originalPosition] = useState(value);

    useEffect(() => {
      if (edit) {
        document.getElementById(`${name}-${index}`).focus();
        document.getElementById(`${name}-${index}`).select()
      }
    }, [edit, index, name]);

    const handleContentClick = (index) => {
      setEdit(true);
      // setText(content);
    };

    function handleChange(event) {
      setText(event.target.value);
    }

    const handleEditableBlur = (event, index) => {
      setEdit(false);
      setText(event.target.value);
      if (onValueChange) onValueChange(path, event.target.value, originalPosition);
    };

    const handleKeyPress = (event, index) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleEditableBlur(event, index);
      }
    }

    return (
      (edit ? (
        <input 
          id={`${name}-${index}`}
          key={`${name}-${index}`}
          type="text" 
          size={4}
          value={text} 
          onChange={handleChange} 
          onBlur={(e) => handleEditableBlur(e, index)}
          onKeyPress={(e) => handleKeyPress(e, index)}
          style={{...editStyle}}
        />
      ) : (
        <button id={`moveto-${name}-${index}`} className="btn btn-sm btn-outline-dark" onClick={(e) => {handleContentClick(e); return false;}}>move</button>
      ))
    );
}

export default EditableMoveStep;