import React from 'react';
import {Container, NavDropdown} from 'react-bootstrap';
import config from '../config';
import { useAuthContext } from '../contexts/AuthContext';

function HeaderNavbar({extraContent, extraUserContent}) {
  const { isAuthenticated, userRoles, logout } = useAuthContext();

  function goToLogout () {
    logout();
  }

  return (
    <nav className="navbar sticky-top navbar-light bg-light justify-content-between">
      <Container>

        <a className="navbar-brand" href={`${config.prefixPath}/projects`} >
        <img style={{marginRight: 10}} src="/logo.svg" alt="Logo" width="30" height="26" className="d-inline-block align-text-top"/>
        <span>{config.siteName}</span>
        </a>
        {(extraContent && isAuthenticated && userRoles.includes('admin')) ? extraContent : ''}
        {isAuthenticated ? <div>
          {extraUserContent ? extraUserContent : null} 
          <NavDropdown style={{display: 'inline', float: 'right'}} title="My Account" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={goToLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
          </div>
         :
        <a href={`${config.prefixPath}/login`}>Login</a>
        }
      </Container>
    </nav>
  );
}

export default HeaderNavbar;