import React from 'react';
import { Navigate } from 'react-router-dom';

import config from '../config';

function HomePage() {
  return (
    <Navigate to={`${config.prefixPath}/projects`} />
  );
}

export default HomePage;